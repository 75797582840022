<template>
  <div>
    <div class="title">{{ $t('fillInformation') }}</div>
    <el-form :rules="rules" ref="form" :model="form" label-width="220px">
      <el-form-item :label="$t('Orderamount')" prop="orderAmount">
        <el-input
          v-model="formFormat.orderAmount"
          style="width: 200px"
          @change="formatAmount"
        ></el-input>
        {{ $t('millionDollars') }}
      </el-form-item>
      <el-form-item
        :label="$t('financingAmountRatio')"
        prop="financingDiscount"
        label-width="250px"
      >
        <el-select v-model="form.financingDiscount" :placeholder="$t('selectAmountRatio')">
          <el-option v-for="item in optionsDiscount" :key="item" :label="item" :value="item"></el-option>
        </el-select>
        <span>&nbsp;%</span>
      </el-form-item>
      <el-form-item :label="$t('financingAmount')" required>
        <span>
          {{
          (form.financingAmount = form.orderAmount
          ? (
          Number(form.orderAmount) *
          (form.financingDiscount / 100)
          ).toFixed(3)
          : '0.00') | toThousandFilterTwo
          }}
          {{ $t('millionDollars') }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('repaymentMethod')" prop="repaymentMethod" label-width="250px">
        <el-select v-model="form.repaymentMethod" :placeholder="$t('chooseRepaymentMethod')">
          <el-option :label="$t('equalPrincipal')" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('dateOfIssuance')" prop="shipmentDate">
        <el-date-picker
          style="width: 200px"
          :pickerOptions="pickerOptions1"
          v-model="form.shipmentDate"
          type="date"
          value-format="yyyy-MM-dd"
          :placeholder="$t('selectDate')"
          @change="changeDate1"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('deadlinefForFund')" required label-width="250px">
        <span>{{ form.deadlineFundRaising }}</span>
      </el-form-item>
      <el-form-item :label="$t('promisedPaymentDateAgain')" prop="promisedPaymentDate">
        <el-date-picker
          style="width: 200px"
          :pickerOptions="pickerOptions2"
          v-model="form.promisedPaymentDate"
          type="date"
          value-format="yyyy-MM-dd"
          :placeholder="$t('selectDate')"
          @change="changeDate2"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('borrowingCycle')" label-width="250px" required>
        <span>{{ form.borrowingCycle }}&nbsp;{{ $t('day') }}</span>
      </el-form-item>
      <el-form-item :label="$t('financingAgent')" prop="financingAgent">
        <el-input style="width: 200px" v-model="form.financingAgent"></el-input>
      </el-form-item>
      <el-form-item :label="$t('phoneNumber')" prop="phoneNumber" label-width="250px">
        <el-input v-model="form.phoneNumber" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('brandOwner')"
        prop="boUserId"
        v-if="brandOwnerList && brandOwnerList.length > 0"
      >
        <el-select
          v-model="form.boUserId"
          :placeholder="$t('selectBrandOwner')"
          style="width: 200px"
        >
          <el-option
            v-for="(item, index) in brandOwnerList"
            :key="index"
            :label="item.boUser.username"
            :value="item.boUser.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('formTitleI18n.ContractReferenceNumber')"
        required
        style="width: 100%"
      >
        <el-input v-model="form.contractNumber" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item :label="$t('uploadOrderFile')" required style="width: 100%">
        <el-upload
          class="upload-demo"
          action="/api/v1/upload/file"
          accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG, .pdf, .PDF"
          :on-remove="handleRemove1"
          :before-remove="beforeRemove"
          :on-success="onSuccess1"
          multiple
          :headers="headers"
          :limit="10"
          :on-exceed="handleExceed"
        >
          <el-button size="small" class="btn-black">
            {{
            $t('fileUpLoad')
            }}
          </el-button>
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('financingPurpose')" style="width: 1100px" prop="fiancingPurpose">
        <el-input type="textarea" v-model="form.fiancingPurpose" maxlength="1500"></el-input>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t('confirmInformation') }}</div>
    <el-form ref="formBank" :model="form" :rules="rules" style="width: 1100px" label-width="220px">
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" prop="areaId">
                <el-select v-model="bankCountryCity" :placeholder="$t('formPlaceHolder.phSelect')" style="width: 100%;"
                           @change="changeCountry"
                >
                    <template v-if="language==='zh-cn'">
                        <el-option
                                v-for="item in optionsCountry"
                                :key="item.code"
                                :label="item.cnName"
                                :value="item.cnName">
                        </el-option>
                    </template>
                    <template v-else>
                        <el-option
                                v-for="item in optionsCountry"
                                :key="item.code"
                                :label="item.enName"
                                :value="item.enName">
                        </el-option>
                    </template>
                </el-select>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')" prop="receivingBank">
            <el-select v-if="optionsBank.length" v-model="bankName" :placeholder="$t('formPlaceHolder.phReceivingBankSel')" style="width: 100%;"
                       @change="changeBank" ref="select"
            >
                <template v-if="language==='zh-cn'">
                    <el-option
                            v-for="item in optionsBank"
                            :key="item.swiftCode"
                            :label="item.cnName"
                            :value="item.cnName">
                        <div class="flex-row">
                            <el-image :src="item.icon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                            {{item.cnName}}
                        </div>
                    </el-option>
                </template>
                <template v-else>
                    <el-option
                            v-for="item in optionsBank"
                            :key="item.swiftCode"
                            :label="item.enName"
                            :value="item.enName">
                        <div class="flex-row">
                            <el-image :src="item.icon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                            {{item.enName}}
                        </div>
                    </el-option>
                </template>
            </el-select>
            <el-input v-else
                      style="width: 100%;"
                      v-model="form.receivingBank"
                      :placeholder="$t('formPlaceHolder.phReceivingBank')"
            ></el-input>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" prop="swiftCode" label-width="250px">
            <el-input v-model="form.swiftCode" maxlength="30" style="width: 100%"></el-input>
        </el-form-item>
      <el-form-item :label="$t('companyCreate.bankNumber')" prop="bankAccountNumber">
        <el-input v-model="form.bankAccountNumber" style="width: 100%"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('companyCreate.bankAccount')"
        prop="accountHolderName"
        label-width="250px"
      >
        <el-input v-model="form.accountHolderName" style="width: 100%"></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button
        class="submit btn-black"
        v-on:click="submit()"
        :disabled="isSubmit"
      >{{ $t('submit') }}</el-button>
      <el-button class="submit" v-on:click="cancel" :disabled="isSubmit">{{ $t('btnI18n.Cancel') }}</el-button>
    </div>
  </div>
</template>
<script>
// import { connect } from '@/utils/blockchain';
import { subDays, format, differenceInDays, addDays } from 'date-fns';
import { Encrypt } from '@/utils/crypto';
import filter from "@/filters";
import {getObjByKeyValue} from "@/utils";

export default {
  data() {
    let regNumZero = (rule, value, callback) => {
      if (value === '' || value === 0 || value === '0' || value === null) {
        callback(new Error(this.$t('completeInput')));
      } else {
        callback();
      }
    };
    return {
      isSubmit: false,
      multipleSelection: [],
      onlineSure: true,
      orderId: '',
      formFormat: {
        orderAmount: "",
      },
        // bank update
        optionsCountry: [],
        optionsBank: [],
        bankCountryCity: "",
        bankName: "",
        // bank update
        optionsDiscount: [],
      form: {
        orderAmount: 0,
        financingDiscount: "",
        repaymentMethod: '1',
        shipmentDate: '',
        borrowingCycle: 30,
        financingAgent: '',
        deadlineFundRaising: '',
        promisedPaymentDate: '',
        boUserId: '',
        orderFile: '',
        fiancingPurpose: '',
        bankAccountNumber: '',
        accountHolderName: '',
        phoneNumber: '',
        contractNumber: '',
          areaId: "",
          areaBankId: "",
          receivingBank: "",
          receivingBankEn: "",
          swiftCode: "",
      },
      rules: {
        orderAmount: [
          { validator: regNumZero, required: true, trigger: 'blur' },
        ],
        financingDiscount: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'change',
          },
        ],
        repaymentMethod: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'change',
          },
        ],
        shipmentDate: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'change',
          },
        ],
        promisedPaymentDate: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'change',
          },
        ],
        borrowingCycle: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'blur',
          },
        ],
        financingAgent: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'blur',
          },
        ],
        contractNumber: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'blur',
          },
        ],
        boUserId: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'change',
          },
        ],
        fiancingPurpose: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'blur',
          },
        ],
        bankAccountNumber: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'blur',
          },
        ],
        accountHolderName: [
          {
            required: true,
            message: this.$t('completeInput'),
            trigger: 'blur',
          },
        ],
        areaId: [{ required: true, message: this.$t('companyCreate.accountCannotEmpty'), trigger: 'blur' }],
          receivingBank: [
              { required: true, message: this.$t('companyCreate.bankCannotEmpty'), trigger: 'blur' },
              { required: true, message: this.$t('companyCreate.bankCannotEmpty'), trigger: 'change' }
          ],
        swiftCode: [
          {
            required: true,
            message: this.$t('companyCreate.swiftCodeCannotEmpty'),
            trigger: 'blur',
          },
            { required: true, message: this.$t('companyCreate.swiftCodeCannotEmpty'), trigger: 'change' }
        ],
      },
      info: {},
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() < addDays(new Date(), 7).getTime();
        },
      },
      pickerOptions2: {
        disabledDate: (time) => {
          return this.dealDisabledDate(time);
        },
      },
      address: null,
      headers: {},
      brandOwnerList: [],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.form.receivablesOrderId = this.$route.query.id;
    }
    this.setOptionsDiscount(95, 99.875, 0.125);
  },
  mounted() {
    this.getBrandOwnerList();
    this.form.shipmentDate = format(addDays(new Date(), 10), 'yyyy-MM-dd');
    this.form.deadlineFundRaising = format(
      subDays(new Date(this.form.shipmentDate), 7),
      'yyyy-MM-dd'
    );
    this.form.promisedPaymentDate = format(
      addDays(new Date(this.form.shipmentDate), 30),
      'yyyy-MM-dd'
    );
    this.headers = { token: this.$store.getters['auth/user'].token };
    this.orderId = this.$route.query.id || '';
      this.getAreaList();
  },
  methods: {
      setOptionsDiscount(start, end, step) {
          start = Number(start);
          end = Number(end);
          step = Number(step);
          let value = start;
          while(value <= end) {
              this.optionsDiscount.push(value);
              value += step;
          }
      },
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/v1/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getBankList(code) {
          let _this = this;
          this.optionsBank = [];
          this.$axios.get("/v1/bank/list", {params: { areaCode: code.toString() || "86" }}).then(res => {
              if (!res.code) {
                  _this.optionsBank = res.data;
              }
          });
      },
      // bank update
    formatAmount() {
      if (/^([0-9]{1}|^[1-9]{1}\d{1,99})(\.\d{1,2})?$/.test(this.formFormat.orderAmount) && this.formFormat.orderAmount > 0) {
        this.formFormat.orderAmount = filter.numberFormat(this.formFormat.orderAmount, 0);
        this.form.orderAmount = filter.reNumber(this.formFormat.orderAmount, 2);
      } else {
        this.formFormat.orderAmount = 1;
        this.form.orderAmount = 1;
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`${this.$t('sureRemove')} ${file.name}？`);
    },
    handleExceed(files, fileList) {
      this.$message({
        message: this.$t('companyCreate.limitedSelected10'),
        type: 'warning',
      });
    },
    handleRemove1(file, fileList) {
      let dataArr = [];
      fileList.map((file) => {
        if (file.response) {
          dataArr.push(file.response.data);
        }
      });
      this.form.orderFile = JSON.stringify(dataArr);
      // this.form.orderFile = undefined;
    },
    onSuccess1(response, file, fileList) {
      if (response.data) {
        let dataArr = [];
        fileList.map((file) => {
          if (file.response) {
            dataArr.push(file.response.data);
          }
        });
        this.form.orderFile = JSON.stringify(dataArr);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    dealDisabledDate(time) {
      return (
        time.getTime() < addDays(new Date(this.form.shipmentDate), 6).getTime()
      );
    },
    getBrandOwnerList() {
      const data = {
        page: 1,
        pageSize: 10000,
      };
      this.$axios
        .get('/v1/supplier/brandowners', { params: data })
        .then((result) => {
          if (result.code === 0 || result.code === '0') {
            this.brandOwnerList = result.data.rows || [];
          } else {
            this.$message({ type: 'error', message: result.message });
          }
        })
        .catch((error) => console.log(error));
    },
    changeDate1() {
      let days = this.form.borrowingCycle < 7 ? 8 : this.form.borrowingCycle;
      if (
        new Date(this.form.promisedPaymentDate).getTime() <=
        new Date(this.form.shipmentDate).getTime() ||
        this.form.borrowingCycle < 7
      ) {
        this.form.promisedPaymentDate = format(
          addDays(new Date(this.form.shipmentDate), days),
          'yyyy-MM-dd'
        );
        this.form.deadlineFundRaising = format(
          subDays(new Date(this.form.shipmentDate), 7),
          'yyyy-MM-dd'
        );
      }
    },
    changeDate2() {
      let days = this.form.borrowingCycle < 7 ? 8 : this.form.borrowingCycle;
      if (
        new Date(this.form.promisedPaymentDate).getTime() <=
        new Date(this.form.shipmentDate).getTime() ||
        this.form.borrowingCycle < 7
      ) {
        this.form.shipmentDate = format(
          subDays(new Date(this.form.promisedPaymentDate), days),
          'yyyy-MM-dd'
        );
        this.form.deadlineFundRaising = format(
          subDays(new Date(this.form.shipmentDate), 7),
          'yyyy-MM-dd'
        );
      }
    },
      // bank update
      changeCountry(e) {
          this.bankName = "";
          this.form.receivingBank = "";
          this.form.receivingBankEn = "";
          this.form.swiftCode = "";
          this.form.receivingBankIcon = "";
          const key = this.language === "zh-cn" ? "cnName" : "enName";
          const {code} = getObjByKeyValue(this.optionsCountry, key, e);
          this.form.areaId = code.toString();
          this.getBankList(code);
      },
      changeBank(e) {
          const key = this.language === "zh-cn" ? "cnName" : "enName";
          const {swiftCode, icon, cnName, enName, id} = getObjByKeyValue(this.optionsBank, key, e);
          this.form.areaBankId = id;
          this.form.receivingBank = cnName;
          this.form.receivingBankEn = enName;
          this.form.swiftCode = swiftCode;
          this.form.receivingBankIcon = icon;
          this.$refs.select.$el.children[0].children[0].setAttribute('style',"background:url("+ icon +") no-repeat 10px;background-size: 20px 20px;color:#333;padding-left: 45px;");
      },
      // bank update
    async submit() {
      this.isSubmit = true;
      let submitForm = true;
      if (
        !this.form.orderFile ||
        this.form.orderFile === '' ||
        this.form.orderFile === undefined
      ) {
        submitForm = false;
        this.isSubmit = false;
        this.$message.error(this.$t('plaseUpload'));
        return false;
      }
      submitForm &&
        this.$refs.form.validate((valid) => {
          if (!valid) {
            submitForm = false;
            this.isSubmit = false;
            this.$message.error(this.$t('submittedFail'));
            return false;
          }
        });
      submitForm &&
        this.$refs.formBank.validate((valid) => {
          if (!valid) {
            submitForm = false;
            this.isSubmit = false;
            this.$message.error(this.$t('submittedFail'));
            return false;
          }
        });
      if (!submitForm) {
        return false;
      }
      let info = this.form;
      if (!this.form.boUserId) {
        delete info.boUserId;
      }
      info.orderAmount = (
        info.orderAmount * this.$enums.UNIT_MILLION
      ).toString();
      info.financingAmount = (
        info.financingAmount * this.$enums.UNIT_MILLION
      ).toString();
      this.$axios.post('/v1/supplier/financ-order', info).then((result) => {
        if (result.code === 0 || result.code === '0') {
          this.$message({
            type: 'success',
            message: this.$t('submittedSuccessfully'),
          });
          this.$router.push({ path: '/financing-list' });
        } else {
          this.$message({ type: 'error', message: result.message });
          submitForm = false;
          this.isSubmit = false;
        }
      }).catch(() => {
        submitForm = false;
        this.isSubmit = false;
      });
      // this.updateOrderStatus();
      // let extraData = {
      //   fileUrl: this.form.fileUrl,
      //   orderNumber: this.form.orderNumber,
      //   account: this.form.account,
      //   user: this.form.user,
      //   bank: this.form.bank,
      //   logoUrl: sessionStorage.getItem("logo-url"),
      //   orderId: this.orderId
      // };
      // let order = (Number(this.form.orderMoney) * this.$enums.UNIT_MILLION).toFixed(2);
      // let orderRate = (Number(this.form.orderMoney) * this.form.rate).toFixed(2) * this.$enums.UNIT_MILLION;
      // await tradeFlowContract.methods.createFinanceRequest(
      //   web3.utils.toWei(orderRate.toString()),
      //   web3.utils.toWei(order.toString()),
      //   this.form.phone.toString(),
      //   this.form.day,
      //   this.form.person,
      //   new Date(this.form.payReceived).valueOf().toString(),
      //   this.form.financingPurpose,
      //   this.form.brandOwnerAddress,
      //   JSON.stringify(extraData)
      // ).send({ from: this.address });
      // this.isSubmit = false;
      // this.$router.push({ path: "/financing-list" });
    },
    async cancel() {
      this.$router.go(-1);
    },
    // updateOrderStatus() {
    //   let params = {
    //     id: this.orderId,
    //     orderStatus: this.$enums.ASSETS_STATUS[2].value
    //   };
    //   this.$axios.post("/loan/user/receivables/change", params).then((result) => {
    //     console.log(result);
    //   });
    // },
  },
  // async beforeCreate() {
  //   await connect();
  //   let accounts = await web3.eth.getAccounts();
  //   this.address = accounts[0];
  // },
  computed: {
    shipmentDate() {
      return this.form.shipmentDate;
    },
    promisedPaymentDate() {
      return this.form.promisedPaymentDate;
    },
      language() {
          return this.$store.getters['auth/language'];
      }
  },
  watch: {
    shipmentDate(val) {
      this.form.deadlineFundRaising = format(
        subDays(new Date(val), 7),
        'yyyy-MM-dd'
      );
      this.form.borrowingCycle = differenceInDays(
        new Date(this.form.promisedPaymentDate),
        new Date(val)
      );
    },
    promisedPaymentDate(val) {
      this.form.borrowingCycle = differenceInDays(
        new Date(val),
        new Date(this.form.shipmentDate)
      );
    },
      language() {
          console.log(this.language);
      }
  },
};
</script>

<style lang="scss" scoped>
.is-checked,
.is-indeterminate {
  .el-checkbox__inner {
    background-color: #81d8d0 !important;
    border-color: #81d8d0 !important;
  }
}
.is-focus,
.el-checkbox__inner:hover {
  // background-color: #81D8D0 !important;
  border-color: #81d8d0 !important;
  .el-checkbox__inner {
    border-color: #81d8d0 !important;
  }
}
.tip-view {
  margin-top: 20px;
  margin-left: 20px;
  span {
    color: #ff0101;
  }
}
.el-switch__label.is-active {
  color: #81d8d0;
}
.text-red {
  font-size: 14px;
  color: #ff0101;
}
.online-view {
  // margin-top: 10px;
  margin-left: 20px;
}
.el-form-item {
  width: 550px;
  display: inline-block;
}
.el-form-item__content {
  margin-left: 0px !important;
}
.end-date {
  .el-form-item {
    width: 160px;
  }
  .el-input,
  .el-input__inner {
    width: 160px;
  }
  .el-form-item__error {
    margin-left: 10px;
  }
}
.el-input,
.el-input__inner {
  width: 330px;
}
.el-textarea,
.el-textarea__inner {
  width: 890px;
  min-height: 80px !important;
}
.box-agree {
  .el-checkbox__inner {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .el-checkbox__inner::after {
    height: 10px;
    left: 7px;
    top: 2px;
  }
}
.submit {
  margin-top: 10px;
  padding: 6px 20px;
}
.el-form-item__error {
  margin-left: 140px;
}
</style>
